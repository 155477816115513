import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";
import Home from "./pages/Home";
import getLPTheme from "./getLPTheme";
import Program from "./pages/Program";
import ChallengePack from "./pages/ChallengePack";
import Challenge from "./pages/Challenge";
import { getSiteSettings } from "./helper/settings.helper";
import { replaceSettings } from "./store/settings";
import { updateIpAddress } from "./store/ip";
import NotFound from "./pages/NotFound";

function App() {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);

  const dispatch = useDispatch();

  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  useEffect(() => {
    getSiteSettings().then((data) => {
      dispatch(replaceSettings(data.settings));
    });

    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        dispatch(updateIpAddress(data.ip));
      })
      .catch((error) => console.error("Error fetching IP:", error));
  }, []);

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <div className="App">
        <Router>
          <Routes>
            <Route
              path="/*"
              element={
                <>
                  <Routes>
                    <Route exact path="/" element={<Home />} />
                    {/* <Route exact path="/:shortUrl" element={<NavPage />} /> */}
                    <Route
                      exact
                      path="/program/:programName"
                      element={<Program />}
                    />
                    <Route
                      exact
                      path="/program/pack/:packName"
                      element={<ChallengePack />}
                    />
                    <Route
                      exact
                      path="/pack/:packName"
                      element={<ChallengePack />}
                    />
                    <Route
                      exact
                      path="/program/pack/challenge/:challengeName"
                      element={<Challenge />}
                    />
                    <Route
                      exact
                      path="/pack/challenge/:challengeName"
                      element={<Challenge />}
                    />
                    <Route
                      exact
                      path="/challenge/:challengeName"
                      element={<Challenge />}
                    />
                    <Route exact path="/not-found" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </>
              }
            />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;

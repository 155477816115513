import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const postHeader = {
  "Content-Type": "application/x-www-form-urlencoded",
  "Access-Control-Allow-Origin": "*",
  Accept: "application/json",
};

export const getSiteSettings = () =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-site-settings`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

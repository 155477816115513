import React from "react";
import { Container, Typography } from "@mui/material";

export default function AdBanner() {
  return (
    <Container
      sx={{
        width: { md: "728px", sm: "320px" },
        height: { md: "90px", sm: "50px" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid grey"
      }}
    >
      <Typography sx={{ fontSize: "28px", color: "grey" }}>HTML5 Ad</Typography>
    </Container>
  );
};

const IP_ADDRESS = "IP_ADDRESS";

export function updateIpAddress(ipAddress) {
  return {
    type: IP_ADDRESS,
    ipAddress,
  };
}

function ipAddress(state = "", action) {
  switch (action.type) {
    case IP_ADDRESS:
      return action.ipAddress;
    default:
      return state;
  }
}

export default ipAddress;
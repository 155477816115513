import React from 'react';
import { Typography, Button, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ my: 5 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          404 - Not Found
        </Typography>
        <Typography variant="subtitle1">
          Oops! The page you are looking for does not exist.
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="contained"
          sx={{ mt: 3 }}
          color="primary"
        >
          Go Home
        </Button>
      </Box>
    </Container>
  );
}

export default NotFound;

import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Card,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";

const generateDeck = (images) => {
  const deck = [...images, ...images];
  return deck.sort(() => Math.random() - 0.5);
};

const Pairs = ({ defaultImage, images, timeToSolve, result }) => {
  const [deck, setDeck] = useState(generateDeck(images));
  const [flipped, setFlipped] = useState([]);
  const [matched, setMatched] = useState([]);
  const [started, setStarted] = useState(false);
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (flipped.length === 2) {
      const [first, second] = flipped;
      if (deck[first] === deck[second]) {
        setMatched([...matched, first, second]);
      }
      setTimeout(() => setFlipped([]), 1000);
    }
  }, [flipped, deck]);

  useEffect(() => {
    let timerInterval;
    if (started) {
      timerInterval = setInterval(() => {
        setTimer((prev) => {
          if (prev >= Number(timeToSolve) * 60) {
            clearInterval(timerInterval);
            result(false);
            return prev;
          }
          return prev + 1;
        });
      }, 1000);
      setIntervalId(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [started]);

  useEffect(() => {
    if (matched.length === deck.length && started) {
      clearInterval(intervalId);
      result(true);
    }
  }, [matched, deck, started, intervalId]);

  const handleClick = (index) => {
    if (!started) {
      setStarted(true);
    }
    if (
      flipped.length === 2 ||
      matched.includes(index) ||
      flipped.includes(index)
    )
      return;
    setFlipped([...flipped, index]);
  };

  const isFlipped = (index) =>
    flipped.includes(index) || matched.includes(index);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };
  return (
    <Container sx={{ px: {sm: 2, xs: 0} }}>
      <Box textAlign="center" my={4}>
        <Typography
          variant="h6"
          sx={{
            color: "#fff",
            fontSize: "22px",
            marginBottom: 3,
            textAlign: "left",
          }}
        >
          Time: {formatTime(timer)}
        </Typography>
        <Grid container spacing={2}>
          {deck.map((image, index) => (
            <Grid item xs={3} key={index}>
              <Card
                onClick={() => handleClick(index)}
                sx={{ px: 5, py: 3, display: "flex", justifyContent: "center", cursor: "pointer" }}
              >
                <CardMedia
                  component="img"
                  height="60"
                  sx={{ width: "auto", height: {sm: "45px", xs: "30px"} }}
                  image={
                    isFlipped(index)
                      ? process.env.REACT_APP_API_IMAGE_URL + image
                      : defaultImage
                      ? process.env.REACT_APP_API_IMAGE_URL + defaultImage
                      : "https://via.placeholder.com/150?text=Back"
                  }
                  alt="Card Image"
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Pairs;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { logEngagement } from "../helper/log.helper";

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export default function QuizCorrect({
  challenegId,
  packId,
  isLast,
  isSingle,
  isDisplayStats,
  successMessage,
  successImage,
  resultMessage1,
  resultMessage2,
  moreUrl,
  correctCount,
  quizCount,
  step,
  quizzes,
  answerStates,
  resultPercent,
  packResultPercent,
  className,
  onNext,
}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const npi = searchParams.get("npi");
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");

  const ipAddress = useSelector((state) => state.ipAddress);

  const handleNavigation = (url) => {
    let payload = {
      npi: npi,
      ipAddress: ipAddress,
      engagementRefId: "Message Page",
      engagementSecRefId: packId ? packId : challenegId,
      engagementType: "Clicked CTA",
      utmSource: utmSource,
      utmMedium: utmMedium,
      timeToComplete: "",
      gameResult: "",
    };
    logEngagement(payload);
    if (url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url; // Default to http if no protocol is specified
      }

      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: {md:3, xs: 2},
        }}
        className={className}
      >
        {!isLast && (
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#333",
              textAlign: "left",
              width: "100%",
            }}
          >
            Question {step} of {quizCount}
          </Typography>
        )}
        <Box
          sx={{
            textAlign: "center",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: {md:"45px", sm: "38px", xs: "32px"},
              fontWeight: 700,
              color:
                isLast && correctCount / quizCount <= 0.5
                  ? "#FF0000"
                  : "#71C9F0",
            }}
          >
            {isLast ? "RESULTS!" : "CORRECT!"}
          </Typography>
        </Box>
        {successImage && (
          <Box
            width={250}
            height={210}
            sx={{
              backgroundImage: `url('${
                process.env.REACT_APP_API_IMAGE_URL + successImage
              }')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Box>
        )}
        {isDisplayStats && (!isLast || isSingle) && (
          <Typography
            sx={{
              fontSize: {md:"24px", sm: "22px", xs: "18px"},
              fontWeight: 700,
              color: "#333",
              textAlign: "center",
            }}
          >
            You{" "}
            {resultPercent === 0
              ? ""
              : `and ${Math.round(resultPercent)}% of users `}
            answered this question correctly
          </Typography>
        )}
        {!isSingle && isDisplayStats && isLast && (
          <Typography
            sx={{
              fontSize: {md:"24px", sm: "22px", xs: "18px"},
              fontWeight: 700,
              color: "#333",
              textAlign: "center",
            }}
          >
            You{" "}
            {packResultPercent === 0
              ? ""
              : `and ${Math.round(packResultPercent)}% of users `}
            answered {correctCount}/{quizCount} questions correctly
          </Typography>
        )}
        {!isSingle && isLast && (
          <Box>
            <List dense={false}>
              {quizzes.map((quiz, index) => {
                return (
                  <ListItem key={`result-${index}`}>
                    <ListItemIcon>
                      {answerStates[index] ? (
                        <CheckBoxIcon sx={{ color: "green" }} />
                      ) : (
                        <DisabledByDefaultIcon sx={{ color: "#ff4569" }} />
                      )}
                    </ListItemIcon>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(quiz.quiz.quizQuestion),
                      }}
                      sx={{
                        color: answerStates[index] ? "green" : "#ff4569",
                        fontSize: "16px",
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
        {!isSingle && isLast ? (
          <Typography
            sx={{
              fontSize: {md:"20px", sm: "20px", xs: "18px"},
              fontWeight: 400,
              color: "#333",
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{
              __html:
                correctCount / quizCount < 0.5
                  ? resultMessage2 ||
                    "Great job! You are really on top of your Knowledge."
                  : resultMessage1 ||
                    "Great job! You are really on top of your Knowledge.",
            }}
          ></Typography>
        ) : (
          <Typography
            sx={{
              fontSize: {md:"20px", sm: "20px", xs: "18px"},
              fontWeight: 400,
              color: "#333",
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{
              __html:
                successMessage && successMessage !== ""
                  ? successMessage
                  : "Great job! You are really on top of your Knowledge.",
            }}
          ></Typography>
        )}
        {isSingle || isLast ? (
          moreUrl &&
          moreUrl !== "" && (
            <Button
              variant="contained"
              size="medium"
              color="success"
              sx={{
                backgroundColor: "#A2D049",
                fontSize: {md:"20px", sm: "18px", xs: "16px"},
                fontWeight: 600,
                color: "#021637",
                width: "250px",
                py: 1,
                "&:hover": {
                  bgcolor: "#bcf154",
                },
              }}
              onClick={() => handleNavigation(moreUrl)}
            >
              Learn More
            </Button>
          )
        ) : (
          <Button
            variant="contained"
            size="large"
            color="success"
            sx={{
              backgroundColor: "#A2D049",
              fontSize: {md:"20px", sm: "18px", xs: "16px"},
              fontWeight: 600,
              color: "#021637",
              width: "250px",
              py: 1,
              "&:hover": {
                bgcolor: "#bcf154",
              },
            }}
            onClick={onNext}
          >
            {quizzes.length === step ? "View Results" : "Next"}
          </Button>
        )}
      </Box>
    </>
  );
}

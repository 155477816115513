import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ProgramGroupItem from "./ProgramGroupItem";

export default function ChallengePack({ programName, programImage, data }) {
  return (
    <Box sx={{ px: { xs: 6 } }}>
      <Typography sx={{ fontSize: "24px", fontWeight: 500, mb: 1 }}>
        {data.category}
      </Typography>
      <Grid container spacing={4}>
        {data.packs.map((pack) => {
          return (
            <Grid item md={3} sm={6} xs={12}>
              <ProgramGroupItem
                name={pack.name}
                link={`${
                  pack.program_id && pack.program_id !== 0 ? "/program" : ""
                }/pack/${
                  pack.short_url && pack.short_url !== ""
                    ? pack.short_url
                    : pack.name
                }?packid=${pack.id}`}
                image={
                  programImage && programImage !== ""
                    ? process.env.REACT_APP_API_IMAGE_URL + programImage
                    : ""
                }
              />
            </Grid>
          );
        })}
        {data.challenges.map((challenge) => {
          return (
            <Grid item md={3} sm={6} xs={12}>
              <ProgramGroupItem
                name={challenge.name}
                link={`${challenge.program_id ? "/program" : ""}${
                  challenge.challenge_pack_id ? "/pack" : ""
                }/challenge/${
                  challenge.short_url && challenge.short_url !== ""
                    ? challenge.short_url
                    : challenge.name
                }?challengeid=${challenge.id}`}
                image={
                  programImage && programImage !== ""
                    ? process.env.REACT_APP_API_IMAGE_URL + programImage
                    : ""
                }
                isChallenge={true}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

const SITE_SETTINGS = "SITE_SETTINGS";

export function replaceSettings(settings) {
  return {
    type: SITE_SETTINGS,
    settings,
  };
}

const defaultSettings = JSON.parse(localStorage.getItem("settings"));

function siteSettings(state = defaultSettings, action) {
  switch (action.type) {
    case SITE_SETTINGS:
      localStorage.setItem("settings", JSON.stringify(action.settings));
      return action.settings;
    default:
      return state;
  }
}

export default siteSettings;

import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography, Button } from "@mui/material";
import { logEngagement } from "../helper/log.helper";

export default function OtherChallengeItem({ challengeId, name, link }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const npi = searchParams.get("npi");
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");

  const ipAddress = useSelector((state) => state.ipAddress);

  const handleLogEngagement = () => {
    if (ipAddress !== "") {
      const payload = {
        npi: npi,
        ipAddress: ipAddress,
        engagementRefId: "Challenge",
        engagementSecRefId: challengeId,
        engagementType: "Liked",
        utmSource: utmSource,
        utmMedium: utmMedium,
        timeToComplete: "",
        gameResult: "",
      };
      logEngagement(payload);
    }
  }

  return (
    <Box
      sx={{
        bgcolor: "#FFFFFF",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
        pb: 3,
      }}
    >
      <Box bgcolor="#72C9F0" height="25px" width="100%" sx={{ borderTopLeftRadius: 6, borderTopRightRadius: 6 }}></Box>
      <Box flex={1} display="flex" flexDirection={"column"} alignItems={"center"} gap={3}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#001232",
            width: "100%",
          }}
        >
          {name}
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={handleLogEngagement}
          href={link}
          color="success"
          sx={{
            backgroundColor: "#A2CF49",
            fontSize: "28px",
            lineHeight: 1,
            color: "#001232",
          }}
        >
          »
        </Button>
      </Box>
    </Box>
  );
}

import React, { useState, useEffect } from 'react';

const Timer = ({ displayTime, seconds }) => {

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return [
      'M', start.x, start.y, 
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');
  };

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  };

  const arcPath = describeArc(50, 50, 20, 0, seconds * 6 * (60 / displayTime));

  return (
    <svg width="100" height="100" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="30" stroke="#b74820" strokeWidth="2" fill="none" />
      <path d={arcPath} fill="none" stroke="#b74820" strokeWidth="2" />
      <circle cx="50" cy="50" r="2" fill="#b74820" />
      <rect x="46" y="9" width="8" height="2" fill="none" stroke="#b74820" strokeWidth="2" />
      <line x1="50" y1="12" x2="50" y2="20" stroke="#b74820" strokeWidth="2" />
      <line x1="45" y1="6" x2="55" y2="6" stroke="#b74820" strokeWidth="2" />
      <line x1="65" y1="38" x2="80" y2="25" stroke="#b74820" strokeWidth="2" />
      <line x1="50" y1="50" x2="50" y2="30" stroke="#b74820" strokeWidth="2" transform={`rotate(${seconds * 6 * (60 / displayTime)} 50 50)`} />
    </svg>
  );
};

export default Timer;

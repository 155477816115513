import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const postHeader = {
  "Content-Type": "application/x-www-form-urlencoded",
  "Access-Control-Allow-Origin": "*",
  Accept: "application/json",
};

export const getProgramByName = (programName) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-program-by-name/${programName}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getProgramById = (id) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-program-by-id/${id}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getPackByPackName = (packName) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-pack-by-pack-name/${packName}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getPackByPNameAndPack = (programName, packName) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-pack-by-program-and-pack-name/${programName}/${packName}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getPackById = (id) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-pack-by-id/${id}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getPacksAndChallengesByProgramId = (programId) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-packs-challenges-by-programId/${programId}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getChallengesByPackId = (packId) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-challenges-by-packId/${packId}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getChallengeById = (id) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-challenge/${id}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getChallengesByName = (challengeName) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-challenges-by-name/${challengeName}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getOtherChallenges = (challengeNames) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: `${API_URL}/get-other-challenges`,
      data: { challengeNames },
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const getShorUrl = (shortUrl) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: `${API_URL}/get-short_url/${shortUrl}`,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const sendQuizAnswer = (id, answer, step, ipAddress) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: `${API_URL}/send-quiz-answer/${id}`,
      data: {
        answer,
        step,
        ipAddress
      },
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const saveQuizResult = (packId, correctness, ipAddress, correctAnswersCount) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: `${API_URL}/save-quiz-result/${packId}`,
      data: {
        correctness,
        ipAddress,
        correctAnswersCount
      },
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

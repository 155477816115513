import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography, Button } from "@mui/material";
import { logEngagement } from "../helper/log.helper";

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export default function GameResult({
  faildMessage,
  successMessage,
  isSuccess,
  moreUrl,
  challengeId,
  className,
}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const npi = searchParams.get("npi");
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");

  const ipAddress = useSelector((state) => state.ipAddress);

  const handleNavigation = (url) => {
    if (url) {
      let payload = {
        npi: npi,
        ipAddress: ipAddress,
        engagementRefId: "Message Page",
        engagementSecRefId: challengeId,
        engagementType: "Clicked CTA",
        utmSource: utmSource,
        utmMedium: utmMedium,
        timeToComplete: "",
        gameResult: "",
      };
      logEngagement(payload);

      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url; // Default to http if no protocol is specified
      }

      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Box
      sx={{
        px: { md: 7, sm: 5, xs: 2 },
        py: { md: 4, sm: 3, xs: 2 },
        border: "2px solid #ccc",
        borderRadius: 10,
        bgcolor: "#fff",
        mt: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
        className={className}
      >
        <Box
          sx={{
            textAlign: "center",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: "42px",
              fontWeight: 700,
              color: isSuccess ? "rgb(113, 201, 240)" : "#FF0000",
            }}
          >
            {isSuccess ? "CONGRATULATIONS!" : "TIME's UP!"}
          </Typography>
        </Box>
        {isSuccess ? (
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 400,
              color: "#333",
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{
              __html:
                successMessage && successMessage !== ""
                  ? successMessage
                  : "Great job! You passed the game in time.",
            }}
          ></Typography>
        ) : (
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              color: "#333",
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{
              __html:
                faildMessage && faildMessage !== ""
                  ? faildMessage
                  : "Unfortunately, you did not pass it in time.",
            }}
          ></Typography>
        )}

        <Button
          variant="contained"
          size="medium"
          color="success"
          sx={{
            backgroundColor: "#A2D049",
            fontSize: "20px",
            fontWeight: 600,
            marginTop: "15px",
            color: "#021637",
            width: "250px",
            py: 1,
            "&:hover": {
              bgcolor: "#bcf154",
            },
          }}
          onClick={() => handleNavigation(moreUrl)}
        >
          Learn More
        </Button>
      </Box>
    </Box>
  );
}

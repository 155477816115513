import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography, Button, IconButton } from "@mui/material";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { logEngagement } from "../helper/log.helper";

export default function ProgramGroupItem({ name, link, image, isChallenge }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const npi = searchParams.get("npi");
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");

  const ipAddress = useSelector((state) => state.ipAddress);

  const handleLogEngagement = () => {
    if (ipAddress !== "") {
      const payload = {
        npi: npi,
        ipAddress: ipAddress,
        engagementRefId: "Program",
        engagementSecRefId: "",
        engagementType: isChallenge
          ? "Challenge Click"
          : "Challenge Pack Click",
        utmSource: utmSource,
        utmMedium: utmMedium,
        timeToComplete: "",
        gameResult: "",
      };
      logEngagement(payload);
    }
  };
  return (
    <Box
      sx={{
        bgcolor: "#72C9F0",
        border: "1px solid #A2CF49",
        borderRadius: 2,
        py: 3,
        px: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: 600,
          color: "#333333",
          textAlign: "center",
          mt: 3,
        }}
      >
        {name}
      </Typography>
      {image && image !== "" && (
        <Box
          width={120}
          height={95}
          mt={2}
          sx={{
            backgroundImage: `url('${image}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Box>
      )}

      <Button
        variant="contained"
        size="large"
        onClick={handleLogEngagement}
        href={link}
        color="success"
        sx={{
          mt: 2,
          backgroundColor: `${isChallenge ? "#A2CF49" : "#001232"}`,
          fontSize: "28px",
          lineHeight: 1,
          color: "#fff",
          ml: "auto",
          width: "70px",
        }}
      >
        »
      </Button>
      <IconButton
        color="inherit"
        aria-label="copy"
        sx={{ alignSelf: "center", position: "absolute", top: 7, right: 7 }}
      >
        {isChallenge ? (
          <CheckBoxOutlineBlankIcon
            fontSize="large"
            sx={{ color: "rgb(191, 78, 20)" }}
          />
        ) : (
          <AutoAwesomeMotionIcon fontSize="large" sx={{ color: "#fff" }} />
        )}
      </IconButton>
    </Box>
  );
}

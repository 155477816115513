import React, { useState, useEffect, useRef } from "react";
import "./ISISection.css";

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

function ISISection({ isiText, isSticky }) {
  const [isOpen, setIsOpen] = useState(false);
  const [stats, setStats] = useState("normal");
  const isiRef = useRef(null);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const mainHeight = document.getElementById("main").offsetHeight;

    if (window.scrollY > 0) {
      if (mainHeight - windowHeight - window.pageYOffset > 0) {
        setStats("fixed");
      } else {
        setStats("relative");
      }
    } else {
      setStats("normal");
    }
  };
  useEffect(() => {
    if (isSticky) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setStats("relative");
    }
    
  }, [isSticky]);

  return (
    <div
      ref={isiRef}
      className={`isi-container ${stats} ${isOpen ? "open" : ""}`}
    >
      <div className={`isi-content ${isOpen ? "open" : "collapsed"}`}>
        <div dangerouslySetInnerHTML={{ __html: decodeHtml(isiText) }}></div>
      </div>
      <button onClick={() => setIsOpen(!isOpen)} className="isi-toggle">
          {isOpen ? "Collapse" : "View More"}
        </button>
    </div>
  );
}

export default ISISection;

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const postHeader = {
  "Content-Type": "application/x-www-form-urlencoded",
  "Access-Control-Allow-Origin": "*",
  Accept: "application/json",
};

export const logEngagement = (data) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: `${API_URL}/log-engagement`,
      data: data,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const logPageVisitEngagement = (data) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: `${API_URL}/log-page-view`,
      data: data,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const logChallengeAttemptEngagement = (data) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: `${API_URL}/log-challenge-attempt`,
      data: data,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });

export const logChallengeClickEngagement = (data) =>
  new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: `${API_URL}/log-challenge-click`,
      data: data,
      headers: postHeader,
    };
    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { error } = err.response.data;
        reject(error.message);
      });
  });
